
import { computed, defineComponent } from "vue";
import { LoadEntitiesFromEntityEvent } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "LoginHistory",
  components: {DateTimeFormat, KtDatatable, Entities},
  setup() {
    const tableHeader = [

      {
        name: "Time",
        key: "time",
        sortable: false,
      },

      {
        name: "Location",
        key: "location",
        sortable: false,
      },

      {
        name: "Coordinates",
        key: "coordinates",
        sortable: false,
      },

      {
        name: "Access",
        key: "accessAble",
        sortable: false,
      },
      {
        name: "User Agent",
        key: "userAgent",
        sortable: false,
      }
    ]
    const loginHistoryPage = computed(() => store.state.UserModule.loginHistoryPage);
    return {
      ...LoadEntitiesFromEntityEvent(Actions.LOAD_LOGIN_HISTORY),
      tableHeader,
      loginHistoryPage
    }
  }
})
